import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import fairExchangeServices from "../../services/match";
import {
  Table,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Alert,
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
} from "reactstrap";
import User from "../../services/user";

import notify from "../../components/notifier";
import userDetail from "../../services/user";
import moment from "moment";
import CommonNavbar from "../../components/ComonNavbar";
import { Link } from "react-router-dom/cjs/react-router-dom";
import logEventHandler from "../../services/logEventHandler";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Withdrawl = (props) => {
  const [userId, setuserId] = useState();
  const [tabPaymentType, settabPaymentType] = useState("bank");
  const [deposit, setdeposit] = React.useState({ amount: "", imageproof: "" });
  const [withdrawl, setwithdrawl] = React.useState({
    amount: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    phonepay_upi: "",
    googlepay_upi: "",
    paytm_upi: "",
    user_upi: "",
    user_sec_code: "",
  });
  const [file, setfile] = React.useState("");
  const [Errors, setErrors] = React.useState({
    amount: "",
    imageproof: "",
    file: "",
    user_upi: "",
  });
  const [withdrawlSubmitted, setwithdrawlSubmitted] = React.useState(false);
  const [Notifications, setNotifications] = React.useState([]);
  const clientInformation = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [userBanks, setUserBank] = useState([]);
  const [activeBank, setActiveBank] = useState({});
  const [btnDisable, setbtnDisable] = useState(false);
  const [isTransactionCodeValid, setIsTransactionCodeValid] = useState(false);
  const [TransactionCode, setTransactionCode] = useState("");
  const [newTransactionCode, setNewTransactionCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasTransactionCode, setHasTransactionCode] = useState(null);
  const [isChangingCode, setIsChangingCode] = useState(false);
  const [error, setError] = useState("");
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [securityCodeFirst,setSecurityCodeFirst] = useState(false);
  const [confirmNewSecurityCode , setConfirmNewSecuirtyCode ] = useState();
  const handleNextClick = () => {
    setShowCodeInput(true);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsChangingCode(false);
    setError("");
  };

  const handleTransactionCodeChange = (e) => {
    setTransactionCode(e.target.value);
  };

  const handleNewTransactionCodeChange = (e) => {
    setNewTransactionCode(e.target.value);
  };

  const handleConfirmNewTransactionCodeChange = (e) => {
    setConfirmNewSecuirtyCode(e.target.value);
  };

  const validateTransactionCode = async () => {
    if (!/^\d{6}$/.test(TransactionCode)) {
      setError("Transaction Code must be of 6 digits.");
      return;
    }

    try {
      const res = await User.validateTransactionCode(TransactionCode);
      if (res.data.status === 1) {
        if (isChangingCode) {
          setIsChangingCode(false);
          setNewTransactionCode("");
        } else {
          toggleModal();
          submitWithdrawal();
        }
      } else {
        setError("Invalid Transaction Code");
        setTransactionCode("");
        setNewTransactionCode("");
      }
    } catch (error) {
      console.error("Error validating Transaction Code:", error);
      setError("Error validating Transaction Code");
      setTransactionCode("");
      setNewTransactionCode("");
    }
  };

  const changeTransactionCode = async () => {
    
    if (!/^\d{6}$/.test(newTransactionCode)) {
      setError("New Transaction Code must be exactly 6 digits.");
      return;
    }

    try {
      let data = {};
      data.previousTransactionCode = hasTransactionCode
        ? TransactionCode
        : null;
      data.newTransactionCode = newTransactionCode;
      const response = await User.changeTransactionCode(data);
      if (response.data.status === 1) {
        setShowCodeInput(false);
        setSecurityCodeFirst(true);
        notify("Transaction code updated successfully. You can now proceed with your withdrawals.", "success", "top-right");
        setHasTransactionCode(true);
        toggleModal();
        setTransactionCode("");
        setNewTransactionCode("");
      } else {
        setError("Failed to update Transaction Code.");
        setTransactionCode("");
        setNewTransactionCode("");
      }
    } catch (error) {
      console.error("Error updating Transaction Code:", error);
      setError("Failed to update Transaction Code.");
      setTransactionCode("");
      setNewTransactionCode("");
    }
  };

  const submitTransactionCode = async() =>{
    if(newTransactionCode != confirmNewSecurityCode)
    {
      notify("Security Code Mismatch", "error", "top-left");
      return;
    }
    await changeTransactionCode();

  };

  const initiateWithdrawal = (e) => {
    e.preventDefault();
    if (!validateWithdrawl(withdrawl, tabPaymentType)) {
      return false;
    }
    toggleModal();
  };

  if (
    clientInformation === null ||
    clientInformation.user.PortalName !== "SportsX9"
  ) {
    props.history.push("/");
  }
  const typeChanges = (ptype) => {
    settabPaymentType(ptype);
  };
  useEffect(() => {
    userDetail.userNotifications().then((response) => {
      setNotifications(response.data.data);
    });
    User.userAccountList()
      .then((response) => {
        if (response && response.data) {
          setUserBank(response.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notify(err.response.data, "error", "top-left");
        }
      });

    // User.UserPrimaryAccount()
    //     .then((response) => {
    //         const { account_holder_name, account_number, ifsc_code, upi_number } = response.data;
    //         setwithdrawl({
    //             account_holder_name: account_holder_name,
    //             account_number: account_number,
    //             ifsc_code: ifsc_code,
    //             user_upi: upi_number
    //         });
    //     })
    //     .catch((err) => {
    //         if (err.response.status === 400) {
    //             notify(err.response.data, "error", "top-left");
    //         }
    //     });
  }, []);
  useEffect(() => {
    if (activeBank) {
      const { account_holder_name, account_number, ifsc_code, upi_number } =
        activeBank;
      setwithdrawl({
        account_holder_name: account_holder_name,
        account_number: account_number,
        ifsc_code: ifsc_code,
        user_upi: upi_number,
        amount: withdrawl.amount,
      });
    }
    const fetchTransactionCodeStatus = async () => {
      try {
        const response = await User.getTransactionCode();
        setHasTransactionCode(response.data.status === 1);
      } catch (error) {
        console.error("Error fetching Transaction Code status:", error);
      }
    };

    fetchTransactionCodeStatus();
  }, [activeBank]);
  let availablebalanceamount = 0;
  let totalExposure = 0;
  let balancemount = 0;
  props.user &&
    props.user.GamewiseExposure &&
    props.user.GamewiseExposure.forEach((expo) => {
      totalExposure += expo.UsedExposure;
    });

  if (props.user && props.user.BalanceAmount) {
    balancemount = props.user.BalanceAmount;
    if (totalExposure && totalExposure > 1) {
      balancemount = balancemount - totalExposure;
    }
    if (props.user.BonusAmount && props.user.BonusAmount > 0) {
      balancemount =
        parseFloat(balancemount) - parseFloat(props.user.BonusAmount);
    }
  }
  if (balancemount >= 1) {
    availablebalanceamount = balancemount;
  }
  // console.log('availablebalanceamount',availablebalanceamount)
  const handleBankChange = (e) => {
    let selectedbank = e.target.value;
    if (!selectedbank) {
      setActiveBank({});
      setwithdrawl({
        account_holder_name: "",
        account_number: "",
        ifsc_code: "",
        user_upi: "",
        amount: withdrawl.amount,
      });
    }
    if (userBanks && userBanks.length > 0) {
      let bank = userBanks.filter((b) => b._id == selectedbank);
      setActiveBank(bank && bank.length > 0 && bank[0]);
    }
  };

  


  const handleChange = (e) => {
    if (e.target.name == "amount") {
      if (availablebalanceamount < e.target.value) {
        setErrors({ amount: "Amount can not be more than Balance" });
      } else {
        setErrors({ amount: "" });
      }
    }
    const wth = Object.assign({}, withdrawl);

    wth[e.target.name] = e.target.value;
    setwithdrawl(wth);
  };
  const convertImage = async (file) => {
    let bimg = file;
    setfile(bimg);
  };
  // console.log("hereeee")
  // console.log("withdrawl.googlepay_upi",withdrawl.googlepay_upi.trim().length)
  const validateWithdrawl = (withdrawl, tabPaymentType) => {
    if (!withdrawl.amount || withdrawl.amount == "" || withdrawl.amount < 500) {
      setErrors({ amount: "Amonut can't be empty or less than 500" });
      return false;
    } else if (availablebalanceamount < withdrawl.amount) {
      setErrors({ amount: "Amount can not be more than Balance" });
      return false;
    }
    if (tabPaymentType == "bank") {
      // console.log('withdrawlaccount_number',typeof(withdrawl.account_number))
      if (
        !withdrawl.account_holder_name ||
        withdrawl.account_holder_name == "" ||
        withdrawl.account_holder_name.trim().length == "0"
      ) {
        setErrors({
          account_holder_name: "Account Holder Name can't be empty",
        });
        return false;
      } else if (
        !withdrawl.account_number ||
        withdrawl.account_number == "" ||
        withdrawl.account_number.length == "0"
      ) {
        setErrors({ account_number: "Account Number can't be empty" });
        return false;
      } else if (
        !withdrawl.ifsc_code ||
        withdrawl.ifsc_code == "" ||
        withdrawl.ifsc_code.trim().length == "0"
      ) {
        setErrors({ ifsc_code: "IFSC Code can't be empty" });
        return false;
      }
    } else if (tabPaymentType == "googlepay") {
      if (withdrawl.googlepay_upi == "") {
        setErrors({
          googlepay_upi: "Please Enter Google Pay Number or UPI ID",
        });
        return false;
      }
    } else if (tabPaymentType == "phonepay") {
      if (withdrawl.phonepay_upi == "") {
        setErrors({ phonepay_upi: "Please Enter Phone Pay Number or UPI ID" });
        return false;
      }
    } else if (tabPaymentType == "paytm") {
      if (withdrawl.paytm_upi == "") {
        setErrors({ paytm_upi: "Please Enter Paytm Number or UPI ID" });
        return false;
      }
    } else {
    }
    return true;
  };

  const IncrementAmount = (Amount) => {
    const dep = Object.assign({}, withdrawl);
    let currentamount =
      !dep.amount || dep.amount == "" ? 0 : parseFloat(dep.amount);
    dep["amount"] = parseFloat(currentamount) + parseFloat(Amount);
    setwithdrawl(dep);
  };

  const submitWithdrawal = async () => {
    setbtnDisable(true);
    let withdrawldata = Object.assign({}, withdrawl);
    withdrawldata["withdrawltype"] = tabPaymentType;
    try {
      const res = await User.submitWithdrawl(withdrawldata);
      if (res) {
        setwithdrawlSubmitted(true);
        if (
          clientInformation.user &&
          clientInformation.user.RefferCode &&
          clientInformation.user.RefferCode != null
        ) {
          logEventHandler(
            clientInformation.user._id,
            "withdrawal",
            clientInformation.user.ClientName,
            clientInformation.user.RefferCode,
            0,
            withdrawl.amount
          );
        }
        window.scrollTo(0, 0);
        setTimeout(() => {
          setwithdrawlSubmitted(false);
        }, 5000);
        setwithdrawl({
          amount: "",
          account_holder_name: "",
          account_number: "",
          ifsc_code: "",
          phonepay_upi: "",
          googlepay_upi: "",
        });
        localStorage.setItem("withdrawlsuccess", true);
        const response = await userDetail.userNotifications();
        setNotifications(response.data.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        notify(
          err.response.data && err.response.data.message,
          "error",
          "top-left"
        );
      }
    } finally {
      setbtnDisable(false);
      setTransactionCode("");
      setNewTransactionCode("");
    }
  };

  return (
    <section className="section p-bg-7 deposit-section">
      <div className="row m-0">
        {/* <div className="col-md-12 p-0">
                    <div className="text-center sport-table-header" style={{background:"#1c1f24"}}>
                        <p style={{fontSize:"30px"}}> </p>
                    </div>
                </div> */}
        {/* <div className="col-md-12 p-0">
                    <Table className="bet-table t-width table-bordered responsive hover table p-bg-5" style={{marginBottom: "0px"}}>
                        <thead className="">
                            <tr>
                            <th className="text-center heading-color "
                                style={{ background: tabPaymentType === 'bank' ? '#f6ab04' : '#303030', cursor: "pointer", color: "white"  }}
                                onClick={() => typeChanges('bank')}>
                                    <i className="icons fa fa-bank"></i><br/>Bank Transfer
                            </th>
                            <th  className="text-center heading-color "
                                style={{ background: tabPaymentType === 'googlepay' ? '#f6ab04' : '#303030', cursor: "pointer", color: "white"  }}
                                onClick={() => typeChanges('googlepay')}><i className="icons fa fa-credit-card"></i><br/>GooglePay
                            </th>
                            
                            <th className="text-center heading-color "
                                style={{ background: tabPaymentType === 'phonepay' ? '#f6ab04' : '#303030', cursor: "pointer", color: "white" }}
                                onClick={() => typeChanges('phonepay')}><i className="icons fa fa-credit-card"></i><br/>PhonePay
                            </th>
                            <th className="text-center heading-color "
                                style={{ background: tabPaymentType === 'paytm' ? '#f6ab04' : '#303030', cursor: "pointer", color: "white" }}
                                onClick={() => typeChanges('paytm')}><i className="icons fa fa-credit-card"></i><br/>Paytm
                            </th>
                            </tr>
                        </thead>
                    </Table>
                </div> */}

        <div className="col-lg-12 mb-2">
          <CommonNavbar />
        </div>

        {/* <span className="round-sec b-balance"><span className="v-chip__content"><i className=" fa fa-credit-card"></i> <span className="mr-2">Balance :</span> <span className="primary--text">{props.user && props.user.BalanceAmount && props.user.BalanceAmount.toFixed(2)}</span></span></span>
                <span className="round-sec b-bonus"><span className="v-chip__content"><i className=" fa fa-credit-card"></i> <span className="mr-2">Bonus :</span> <span className="primary--text">{props.user && props.user.BonusAmount && props.user.BonusAmount.toFixed(2)}</span></span></span>
                <span className="round-sec"><span className="v-chip__content"><i className=" fa fa-credit-card"></i> <span className="mr-2">Available withdrawal :</span> <span className="primary--text">{availablebalanceamount && availablebalanceamount.toFixed(2)}</span></span></span> */}

        {withdrawlSubmitted && (
          <div className="col-md-12 form-group">
            <Alert
              color="success"
              style={{
                backgroundColor: "#008000",
                color: "#fff",
                textAlign: "left",
                fontWeight: 800,
                fontSize: "14px",
              }}
            >
              Your withdrawal has been submitted, Money will be transferred
              shortly.
            </Alert>
          </div>
        )}
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="num-wraper num">
                <h5>Available Balance</h5>
                <h5>
                  {props.user &&
                    props.user.BalanceAmount &&
                    props.user.BalanceAmount.toFixed(2)}
                </h5>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="bal-wraper num withdraw-bal">
                <h5>Bonus</h5>
                <h5>
                  {props.user &&
                    props.user.BonusAmount &&
                    props.user.BonusAmount.toFixed(2)}
                </h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="bal-wraper num withdraw-bal">
                <h5>Cashable Balance</h5>
                <h5>
                  {availablebalanceamount && availablebalanceamount.toFixed(2)}
                </h5>
              </div>
            </div>
          </div>
          {/* <div className="motions-text withdraw-promo">
                        <h5>Promotions</h5>
                    </div>
                    <div className="row mob-card">
                        <div className="main-card">
                            <div className="col-lg-3 promo-card">
                                <Card className="card-bg">
                                    <CardImg top width="100%" src="/assets/images/Mask group.png" alt="Card image cap" />
                                    <CardBody>
                                        <CardSubtitle className="card-title">First Time Deposit</CardSubtitle>
                                        <CardText className="card-text mt-0">100% Bonus on first time deposit</CardText>
                                        <div className="btn-group mt-2 d-flex">

                                            <Button className="btn-border"><img src="/assets/images/copy_icon.png" alt="" />FTDAUG100</Button>
                                            <Button className="btn-bg">Show Details
                                                <img src="/assets/images/Arrow-3.png" alt="" />
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 promo-card">
                                <Card className="card-bg">
                                    <CardImg top width="100%" src="/assets/images/Mask group.jpg" alt="Card image cap" />
                                    <CardBody>
                                        <CardSubtitle className="card-title">First Time Deposit</CardSubtitle>
                                        <CardText className="card-text mt-0">100% Bonus on first time deposit</CardText>
                                        <div className="btn-group mt-2 d-flex">

                                            <Button className="btn-border"><img src="/assets/images/copy_icon.png" alt="" />FTDAUG100</Button>
                                            <Button className="btn-bg">Show Details
                                                <img src="/assets/images/Arrow-3.png" alt="" />
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 promo-card">
                                <Card className="card-bg">
                                    <CardImg top width="100%" src="/assets/images/teenpati.png" alt="Card image cap" />
                                    <CardBody>
                                        <CardSubtitle className="card-title">First Time Deposit</CardSubtitle>
                                        <CardText className="card-text mt-0">100% Bonus on first time deposit</CardText>
                                        <div className="btn-group mt-2 d-flex">

                                            <Button className="btn-border"><img src="/assets/images/copy_icon.png" alt="" />FTDAUG100</Button>
                                            <Button className="btn-bg">Show Details
                                                <img src="/assets/images/Arrow-3.png" alt="" />
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 promo-card">
                                <Card className="card-bg">
                                    <CardImg top width="100%" src="/assets/images/Mask-dice.jpg" alt="Card image cap" />
                                    <CardBody>
                                        <CardSubtitle className="card-title">First Time Deposit</CardSubtitle>
                                        <CardText className="card-text mt-0">100% Bonus on first time deposit</CardText>
                                        <div className="btn-group mt-2 d-flex">

                                            <Button className="btn-border"><img src="/assets/images/copy_icon.png" alt="" />FTDAUG100</Button>
                                            <Button className="btn-bg me-2">Show Details
                                                <img src="/assets/images/Arrow-3.png" alt="" />
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div> */}
          <div className="col-lg-12 payment-text">
            <h4>Select Payment Method</h4>
          </div>

          <div className="row border-around-all">
            <div className="col-lg-3">
              <div
                className="bank-wraper mb-2"
                style={{
                  background: tabPaymentType === "bank" ? "FFC11C" : "#FFC11C",
                }}
                onClick={() => typeChanges("bank")}
              >
                <p>
                  <img src="/assets/images/Bank.png" alt="Bank" />
                </p>
                <h5>Bank Transfer</h5>
                <div class="form-check">
                  <Input type="radio" name="radio1" />
                </div>
              </div>
              <div className="bank-wraper-color mb-2">
                <p>
                  <img src="/assets/images/cryft.png" alt="Bank" />
                </p>
                <h5>Crypto</h5>
                <div class="form-check">
                  <Input type="radio" name="radio1" />
                </div>
              </div>
            </div>
            <div className="col-lg-9 border-all">
              <div className="row">
                <div className="col-lg-12">
                  <FormGroup>
                    <ul className="stack-items">
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(2000)}
                      >
                        ₹2000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(4000)}
                      >
                        ₹4000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(8000)}
                      >
                        ₹8000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(10000)}
                      >
                        ₹10,000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(20000)}
                      >
                        ₹20,000
                      </li>
                      <li
                        className="s-item"
                        onClick={() => IncrementAmount(100000)}
                      >
                        ₹100,000
                      </li>
                    </ul>
                  </FormGroup>
                </div>
                <div className="row m-0 p-0">
                  <div className="col-lg-5 mb-2">
                    <div>
                      <span style={{ color: "red" }}>{Errors.amount}</span>
                    </div>
                    <div className="amount-box">
                      <Label for="amount" className="inner-label">
                        Enter Amount
                      </Label>
                      <img
                        className=" pr-2"
                        src="/assets/images/decrement.png"
                        alt=""
                        onClick={() => IncrementAmount(-1)}
                      />
                      <Input
                        type="number"
                        name="amount"
                        className=""
                        id="amount"
                        placeholder="Enter Amount"
                        onChange={handleChange}
                        value={withdrawl.amount}
                        min="500"
                      />

                      <img
                        className=" pl-2"
                        src="/assets/images/increment.png"
                        alt=""
                        onClick={() => IncrementAmount(1)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 mb-2">
                    <div className="amount-boxsecond">
                      <select
                        className="select-box"
                        value={activeBank._id}
                        onChange={(e) => handleBankChange(e)}
                      >
                        <option value={``}>Select Bank</option>
                        {userBanks &&
                          userBanks.length > 0 &&
                          userBanks.map((bank) => (
                            <option value={bank._id}>
                              {bank.account_holder_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2 mb-2">
                    <Link
                      to="/wallet/AccountDetail"
                      className="btn-success next-link"
                    >
                      Add Bank
                    </Link>
                  </div>
                </div>
                <div className="row m-0 mt-3 p-0">
                  <div className="col-lg-6">
                    <div className="account-input">
                      <Input
                        type="text"
                        name="account_holder_name"
                        className="custom-input"
                        id="account_holder_name"
                        placeholder="Enter Account Holder Name"
                        onChange={handleChange}
                        value={withdrawl.account_holder_name}
                      />
                      <span style={{ color: "red" }}>
                        {Errors.account_holder_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="account-input">
                      <Input
                        type="number"
                        name="account_number"
                        className="custom-input"
                        id="account_number"
                        placeholder="Enter Account Number"
                        onChange={handleChange}
                        value={withdrawl.account_number}
                      />
                      <span style={{ color: "red" }}>
                        {Errors.account_number}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row m-0 p-0 account-input">
                  <div className="col-lg-6">
                    <div className="account-input">
                      <Input
                        type="text"
                        name="ifsc_code"
                        className="custom-input"
                        id="ifsc_code"
                        placeholder="Enter IFSC Code"
                        onChange={handleChange}
                        value={withdrawl.ifsc_code}
                      />
                      <span style={{ color: "red" }}>{Errors.ifsc_code}</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="account-input">
                      <Input
                        type="text"
                        name="user_upi"
                        className="custom-input"
                        id="user_upi"
                        placeholder="Enter UPI"
                        onChange={handleChange}
                        value={withdrawl.user_upi}
                      />
                      <span style={{ color: "red" }}>{Errors.user_upi}</span>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                                        <div className="account-input">
                                            <Input
                                                type="text"
                                                name="user_sec_code"
                                                className="custom-input"
                                                id="user_sec_code"
                                                placeholder="Enter Transaction Code"
                                                onChange={handleChange}
                                                value={withdrawl.user_sec_code}
                                            />
                                            <span style={{ color: "red" }}>{Errors.user_upi}</span>
                                        </div>
                                    </div> */}
                  <div className="col-lg-12 mt-4">
                    <div className="submit-depoist">
                      <Button
                        type="submit"
                        className="btn-success"
                        style={{ marginBottom: "10px" }}
                        disabled={btnDisable}
                        onClick={initiateWithdrawal}
                      >
                        {btnDisable ? "Please Wait..." : "Submit"}
                      </Button>
                      &nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section end */}
        </div>
        <div className="col-md-12">
          <div className="d-w sport-table-header ">
            <p> Withdrawal History</p>
          </div>
        </div>
        <div className="col-md-12 mb-2">
          <div style={{ width: "100%", overflow: "auto" }}>
            <Table className="account-Notification t-width table-bordered responsive hover table p-bg-5">
              <thead className="thead-dark">
                <tr>
                  <th className="text-left" scope="col">
                    Sr no
                  </th>
                  <th className="text-left" scope="col">
                    Date
                  </th>
                  <th className="text-right" scope="col">
                    Amount
                  </th>
                  <th className="text-right" scope="col">
                    Description
                  </th>
                  <th className="text-right" scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {Notifications.length ? (
                  Notifications.filter((n) => n.withdrawltype).map(
                    (Notification, idx) => (
                      <tr key={Notification._id}>
                        <td>{idx + 1}</td>
                        <td className="text-left">
                          {moment(Notification.CreatedAt).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </td>
                        <td
                          className={`text-right ${
                            Notification.status == 0
                              ? "warning"
                              : Notification.status == 1
                              ? "success"
                              : "danger"
                          }`}
                        >
                          {Notification.amount}
                        </td>
                        <td className="text-right">
                          {" "}
                          {Notification.description &&
                            Notification.description}{" "}
                        </td>
                        <td className="text-right" style={{ color: "green" }}>
                          {Notification.status == 0 ? (
                            <span className="pending-status">Pending</span>
                          ) : Notification.status == 1 ? (
                            <span className="approved-status">Approved</span>
                          ) : (
                            <span className="cancel pending-status">
                              Cancelled
                            </span>
                          )}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">No record found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        className="loginModal dd-modal  modal-dialog-centered"
        onClosed={() =>{
          setSecurityCodeFirst(false);
          setShowCodeInput(false);
        } 
        }
      >
        <ModalHeader
          toggle={toggleModal}
          className="text-warning border-0"
        ></ModalHeader>
        <ModalBody className="loginc withdrawal text-center text-light">
          {error && <Alert color="danger">{error}</Alert>}
          
          <div class="deposithead mb-3">
            {(hasTransactionCode)
              ? isChangingCode
                ? "Change Transaction Code"
                : "Enter Transaction Code"
              : "Set Transaction Code"}
          </div>

          {!hasTransactionCode && !showCodeInput && !securityCodeFirst && (
          <div className="security-code-instructions">
            {/* <h5 className="mb-3">Security Transaction Code</h5> */}
            <p className="text-light mb-3">
              A transaction code adds an extra layer of security to your account. 
              This 6-digit code will be required for sensitive actions like withdrawals 
              or changing account settings.
            </p>
            <div className="security-benefits mb-3">
              <div className="mb-2">
                <i className="fa fa-lock text-warning mr-2"></i>
                Prevents unauthorized transactions
              </div>
              <div className="mb-2">
                <i className="fa fa-shield text-warning mr-2"></i>
                Protects your account from potential misuse
              </div>
              <div>
                <i className="fa fa-key text-warning mr-2"></i>
                Adds a personal security layer
              </div>
            </div>
          </div>
        )}
          {hasTransactionCode && !isChangingCode && (
            <FormGroup>
              {/* <Label for="TransactionCode" className="text-light">
                Transaction Code
              </Label> */}
              <Input
                type="password"
                name="TransactionCode"
                id="TransactionCode"
                placeholder="Enter your 6-digit Transaction Code"
                value={TransactionCode}
                onChange={handleTransactionCodeChange}
                maxLength="6"
                pattern="\d*"
                className="bg-secondary text-light"
              />
            </FormGroup>
          )}

            {isChangingCode && (
            <FormGroup>
              {/* <Label for="newTransactionCode" className="text-light">
                New Transaction Code
              </Label> */}
              <Input
                type="password"
                name="newTransactionCode"
                id="newTransactionCode"
                placeholder="Enter Transaction Code"
                value={newTransactionCode}
                onChange={handleNewTransactionCodeChange}
                maxLength="6"
                pattern="\d*"
                className="bg-secondary text-light"
              />
            </FormGroup>
          )}
          {(isChangingCode || !hasTransactionCode) && showCodeInput && (
            <FormGroup>
              {/* <Label for="newTransactionCode" className="text-light">
                New Transaction Code
              </Label> */}
              <Input
                type="password"
                name="newTransactionCode"
                id="newTransactionCode"
                placeholder="Enter New Transaction Code"
                value={newTransactionCode}
                onChange={handleNewTransactionCodeChange}
                maxLength="6"
                pattern="\d*"
                className="bg-secondary text-light custom-input"
              />

              <Input
                type="password"
                name="confirm-newTransactionCode"
                id="newTransactionCode"
                placeholder="Confirm Transaction Code"
                value={confirmNewSecurityCode}
                onChange={handleConfirmNewTransactionCodeChange}
                maxLength="6"
                pattern="\d*"
                className="bg-secondary text-light custom-input"
                style={{marginTop:"1rem"}}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter className="text-center border-0 py-0 ">
        {!hasTransactionCode && !showCodeInput && !securityCodeFirst&& (
          <Button
            className="d-btn btn btn-success btn btn-secondary"
            onClick={handleNextClick}
          >
            Next
          </Button>
        )}
          {hasTransactionCode && !isChangingCode && (
            <>
              <Button
                className="btn d-btn btn-success btn btn-secondary"
                onClick={validateTransactionCode}
              >
                Submit
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
              {/* <br />
              <a href="#" onClick={() => setIsChangingCode(true)}>
                Change Code
              </a> */}
            </>
          )}
          {/* {(!hasTransactionCode) && (
            <>
              <Button
                className="d-btn btn btn-success btn btn-secondary"
                onClick={changeTransactionCode}
              >
                Next
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </>
          )} */}

            {(isChangingCode || showCodeInput)  && (
            <>
              <Button
                className="d-btn btn btn-success btn btn-secondary"
                onClick={hasTransactionCode ? changeTransactionCode : submitTransactionCode}
              >
                {hasTransactionCode ? "Update Code" : "Submit"}
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
        {hasTransactionCode && !isChangingCode && (
          <a className="change-code-btn" href="#" onClick={() => setIsChangingCode(true)}>
            Change Code
          </a>
        )}
      </Modal>
    </section>
  );
};

export default withRouter(Withdrawl);